<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters v-if="!loading && dataSet">
        <v-col cols="12" md="4">
          <div class="pa-2">
            <h2>Event Hari Ini</h2>
            <div class="my-3 d-flex align-center justify-space-between">
              <h5 class="not_bold" style="color: #9ba0a3">
                Jangan lewatkan jadwalmu!
              </h5>
              <v-btn
                v-if="role == 'MASTERADMIN' || role == 'ADMIN'"
                fab
                x-small
                class="bg_color1"
                dark
                depressed
                @click="createEvent"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </div>
            <div v-for="(item, n) in dataSet" :key="n">
              <v-card
                v-if="
                  $date(new Date(item.start_date)).format('YYYY-MM-DD') <=
                    date &&
                  $date(new Date(item.end_date)).format('YYYY-MM-DD') >= date
                "
                flat
                color="#052633"
                class="pa-2 my-2"
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center txt_white">
                    <h5 class="not_bold">
                      {{
                        $date(new Date(item.start_date)).format("MMM DD, YYYY")
                      }}
                    </h5>
                    &nbsp; - &nbsp;
                    <h5 class="not_bold">
                      {{
                        $date(new Date(item.end_date)).format("MMM DD, YYYY")
                      }}
                    </h5>
                  </div>
                  <div>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon dark v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <v-btn
                              block
                              color="transparent"
                              class="text-capitalize"
                              @click="editStatus(item)"
                              depressed
                            >
                              <div class="d-flex align-center">
                                <v-icon>mdi-pencil</v-icon>
                                <h4 class="not_bold">Edit Status</h4>
                              </div>
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <v-btn
                              block
                              color="transparent"
                              class="text-capitalize"
                              depressed
                              @click="deleteData(item)"
                            >
                              <div class="d-flex align-center">
                                <v-icon color="red">mdi-delete</v-icon>
                                <h4 class="not_bold" style="color: red">
                                  Delete
                                </h4>
                              </div>
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
                <h4 class="txt_white">{{ item.title }}</h4>
                <h5 class="txt_white not_bold">
                  {{ item.deskripsi ? item.deskripsi : "-" }}
                </h5>
              </v-card>
            </div>
            <div v-if="no_event">
              <div
                class="d-flex align-center justify-center flex-column"
                style="border-top: 1px solid #cacaca"
              >
                <img
                  src="../../assets/img/error.png"
                  class="mt-2"
                  alt="logo-error"
                  width="100px"
                  style="z-index: 1"
                />
                <p
                  class="medium_txt text-center"
                  style="padding-top: 8px; color: #9ba0a3"
                >
                  Tidak ada event hari ini
                </p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <div class="pa-2">
            <div class="d-flex align-center">
              <v-icon color="#052633">mdi-calendar</v-icon>
              <h4 class="ml-2 txt_color_default mr-2">Calendar</h4>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="18" color="#052633" v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <span>Klik tanggal, untuk mellihat detail event di tanggal itu</span>
              </v-tooltip>
            </div>
            <v-divider class="my-4"></v-divider>
            <v-row class="fill-height">
              <v-col>
                <v-sheet height="64">
                  <v-toolbar flat>
                    <v-menu bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mr-4"
                          outlined
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon> mdi-chevron-right </v-icon>
                    </v-btn>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                    @change="updateRange"
                    @click:date="viewDay"
                    @click:more="viewDay"
                  ></v-calendar>
                  <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-y
                  >
                    <v-card color="grey lighten-4" flat>
                      <v-toolbar :color="selectedEvent.color" dark>
                        <v-toolbar-title
                          v-html="selectedEvent.name"
                        ></v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <span v-html="selectedEvent.details"></span>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <div v-if="loading" class="d-flex align-start">
        <v-skeleton-loader
          width="60%"
          type="article, actions"
        ></v-skeleton-loader>
        <v-skeleton-loader type="date-picker"></v-skeleton-loader>
      </div>
    </div>
    <div class="pa-2">
      <dialog-large
        :d_large="d_large"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { NavbarProfile },
  name: "listSchedule",
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      loading: true,
      d_large: false,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Schedule",
          disabled: true,
          href: "#",
        },
      ],
      event: [],
      date: this.$date(new Date()).format("YYYY-MM-DD"),
      dataSet: null,
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      no_event: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    createEvent() {
      localStorage.setItem("creating", true);
      this.$router.push("/admin/mo/event/create");
      // this.inventory = "";
      // this.d_type = "c_schedule";
      // this.d_title = "Tambah Event";
      // this.d_large = true;
      // this.line = true;
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/schedule`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          let tempData = [];
          data.forEach((item) => {
            if (item.start_date && item.end_date) {
              tempData.push({
                id: item.id,
                id_event: item.id_event,
                customer: item.customer,
                tipe: item.tipe,
                layanan: item.layanan,
                start_date: new Date(
                  `${item.start_date}T${item.start_time}`
                ).getTime(),
                temp_start_date: this.$date(
                  new Date(`${item.start_date}T${item.start_time}`)
                ).format("YYYY-MM-DD"),
                end_date: new Date(
                  `${item.end_date}T${item.end_time}`
                ).getTime(),
                temp_end_date: this.$date(
                  new Date(`${item.end_date}T${item.end_time}`)
                ).format("YYYY-MM-DD"),
                start_time: item.start_time,
                end_time: item.end_time,
                title: item.title,
                deskripsi: item.deskripsi,
                step: item.step,
                status: item.status,
                klien: item.klien,
              });
            }
          });
          this.dataSet = tempData;
          this.startDay(this.date);

          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    viewDay({ date }) {
      console.log(date);
      this.focus = date;
      this.type = "day";
      this.date = date;
      let is_event_exist = false;
      for (let i = 0; i < this.dataSet.length; i++) {
        if (
          this.dataSet[i].start_date <=
            new Date(`${this.date}T00:00:00`).getTime() &&
          this.dataSet[i].end_date >=
            new Date(`${this.date}T23:59:59`).getTime()
        ) {
          is_event_exist = true;
          console.log("ada event");
          break;
        } else if (
          this.$date(new Date(this.dataSet[i].start_date)).format(
            "YYYY-MM-DD"
          ) >= this.$date(new Date(this.date)).format("YYYY-MM-DD") &&
          this.$date(new Date(this.dataSet[i].end_date)).format("YYYY-MM-DD") <=
            this.$date(new Date(this.date)).format("YYYY-MM-DD")
        ) {
          is_event_exist = true;
          console.log("ada event di else");
          break;
        }
      }
      if (!is_event_exist) {
        this.no_event = true;
        console.log("tidak ada event");
      } else {
        this.no_event = false;
      }
    },
    startDay({ date }) {
      this.date = date;
      let is_event_exist = false;
      for (let i = 0; i < this.dataSet.length; i++) {
        if (
          this.dataSet[i].start_date <=
            new Date(`${this.date}T00:00:00`).getTime() &&
          this.dataSet[i].end_date >=
            new Date(`${this.date}T23:59:59`).getTime()
        ) {
          is_event_exist = true;
          break;
        } else if (
          this.$date(new Date(this.dataSet[i].start_date)).format(
            "YYYY-MM-DD"
          ) >= this.$date(new Date(this.date)).format("YYYY-MM-DD") &&
          this.$date(new Date(this.dataSet[i].end_date)).format("YYYY-MM-DD") <=
            this.$date(new Date(this.date)).format("YYYY-MM-DD")
        ) {
          is_event_exist = true;
          break;
        }
      }
      if (!is_event_exist) {
        this.no_event = true;
      } else {
        this.no_event = false;
      }
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];
      for (let i = 0; i < this.dataSet.length; i++) {
        let start = this.dataSet[i].start_date;
        let end = this.dataSet[i].end_date;
        events.push({
          name: this.dataSet[i].title,
          start: start,
          end: end,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          details: this.dataSet[i].deskripsi,
          timed: true,
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    editStatus(item) {
      this.inventory = item;
      this.d_type = "u_status";
      this.d_title = "Edit Status";
      this.d_create = true;
      this.line = true;
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/schedule/delete`,
        deleteType: "Schedule",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Schedule";
      this.d_small = true;
      this.line = true;
    },
  },
};
</script>

<style></style>
